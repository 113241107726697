<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{trans('pw-personal-information',196)}}</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',193)}}</span>
            </div>
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',193)}}</span>
            </div>
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('personal-info',172)}}</div>
                    <b-row class="mb-3">
                        <b-col>
                             <app-radio-group :label="trans('pw-gender-question',196)" v-model="cApplicationData.person_data.sex" :options="sex" validatorRules="required" validatorName="Sex" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                        </b-col>
                    </b-row>

                    <app-row-left-label :slots="[4,2,6]" class="mb-3">
                        <template v-slot:col-1>
                            <app-input :label="trans('first-name',182)" v-model="cApplicationData.person_data.first_name" validatorRules="required" validatorName="Voornaam" mask="lettersE1-20" />
                        </template>

                        <template v-slot:col-2>
                            <app-input :label="trans('inbetween-name',182)" v-model="cApplicationData.person_data.inbetween_name" mask="lettersE1-10" />
                        </template>

                        <template v-slot:col-3>
                            <app-input :label="trans('last-name',182)" v-model="cApplicationData.person_data.last_name" validatorRules="required" validatorName="Achternaam" mask="lettersE1-30" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[4,8]" class="mb-3">
                        <template v-slot:col-1>
                            <app-date :label="trans('pw-birth-date',196)" v-model="cApplicationData.person_data.birth_date" validatorRules="required|date-check|age-check" validatorName="Geboortedatum" />
                        </template>

                        <template v-slot:col-2>
                            <app-input :label="trans('pw-birth-place',196)" v-model="cApplicationData.person_data.birth_place" validatorRules="required" validatorName="Geboorteplaats" mask="lettersE1-25" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" class="mb-3">
                        <template v-slot:col-1>
                            <app-email :email.sync="cApplicationData.person_data.email" :label="trans('pw-email',196)" validatorRules="required" customSpinnerClass="app-local-input-spinner"/>
                        </template>

                        <template v-slot:col-2>
                            <label class="flabel mb-0"> {{trans('pw-phone',196)}} </label>
                             <app-phone :prefix.sync="cApplicationData.person_data.phone_prefix" :phone.sync="cApplicationData.person_data.phone_number" :isRequired="true" />
                        </template>  
                    </app-row-left-label>
            </b-card>

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('address-details',3)}}</div>
                <app-wizard-address :address.sync="cApplicationData.address" />
            </b-card>

            <b-card class="mb-3" v-if="cApplicationData.person_data.big_required == 1">
                <div class="app-card-profile-title mb-3">{{trans('pw-big-registered',196)}}</div>
                <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                    <template v-slot:col-1>
                        <div class="flabel">{{trans('pw-big-registered-question',196)}}</div>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group :options="big" v-model="cApplicationData.big.is_big_number" validatorRules="required" validatorName="Big"/>
                    </template>
                </app-row-left-label>
                    <app-wizard-big v-if="cApplicationData.big.is_big_number" class="mt-3 mb-3" :bigData.sync="cApplicationData.big" />
            </b-card>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

                
    </div>
</template>

<script>
import AppEmail from '@core/components/AppEmail.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppWizardAddress from '@core/components/Address/AppWizardAddress.vue';
import AppWizardBig from '@core/components/Big/AppWizardBig.vue';
import AppRadioGroup from '@core/components/AppRadioGroup.vue';

export default {
    components: {
        AppEmail,
        AppPhone,
        AppWizardAddress,
        AppWizardBig,
        AppRadioGroup
    },

    props: ["applicationData", "showSuccessAlert", "showUnsuccessAlert", "loading"],

    created(){
        this.loadAddress()
    },
    
    watch:{
        showSuccessAlert(){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        }
    },

    computed: {
        cApplicationData: {
            get() { return this.applicationData },
            set(value) { this.$emit('update:applicationData', value) }
        },

        cIbanLabel() {
            if(this.applicationData.person_data.applicant_type == 1){//UZK
                return this.trans('pw-account-number',196);
            }else if(this.applicationData.person_data.applicant_type == 2){//ZZP
                return this.trans('pw-business-bank-account-number',196);
            }else{
                return this.trans('pw-iban',196);
            }
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            sex: [
                { value: 1, text: this.trans('gender-male',184) },
                { value: 2, text: this.trans('gender-female',184) },
            ],
            big: [
                { value: 1, text: this.trans('yes-option',3) },
                { value: 0, text: this.trans('no-option',3) },
            ],
            address: {
                post_code: null,
                city: null,
                house_number: null,
                house_number_additional: null,
                street: null,
                latitude: null,
                longitude: null,
                type: null,
                country: null
            }
        }
    },
    
    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },

        loadAddress(){
                this.address.post_code = this.cApplicationData.post_code;
                this.address.city = this.cApplicationData.city,
                this.address.house_number = this.cApplicationData.house_number,
                this.address.house_number_additional = this.cApplicationData.house_number_additional,
                this.address.street = this.cApplicationData.street,
                this.address.latitude = this.cApplicationData.latitude,
                this.address.longitude = this.cApplicationData.longitude,
                this.address.type = this.cApplicationData.address_type,
                this.address.country = {name: this.cApplicationData.country_name, value: this.cApplicationData.id_country_data_main};
        }
    }
};
</script>

<style></style>
<style lang="scss">
</style>
