<template>
    <div>
    
        <h1 class="mt-2 mb-4">{{trans('fp-riskyacts',172)}}</h1>  

        <b-alert variant="success" :show="showSuccessAlert" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',193)}}</span>
            </div>
            
        </b-alert>

        <b-alert variant="danger" :show="showUnsuccessAlert" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',193)}}</span>
            </div>
            
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3" v-if="cApplicationData.competences != null">
                
                <b-row v-if="array_functions.includes(cApplicationData.id_function_data_main) && !loading_competences">
                    <b-col class="mb-1">
                        <div class="flabel">{{trans('select-risky-actions',172)}}</div>
                        <div class="filters">
                            <b-button class="app-input-min-height app-list-button app-local-btn" v-bind:class="{ 'btn-secondary-grey': !selected_all, 'app-list-button-selected':  selected_all }" v-on:click="selectAll()" id="all">
                               {{trans('select-all',175)}}
                            </b-button>
                            <b-button class="app-input-min-height app-list-button app-local-btn" v-bind:class="{ 'btn-secondary-grey': !item.selected, 'app-list-button-selected':  item.selected }" v-for="item in cApplicationData.competences" :key="item.id_competence_const_group" v-on:click="selectOne(item)">
                                {{item.competence_group_name}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

                <b-row v-if="!array_functions.includes(cApplicationData.id_function_data_main)">
                    <b-col class="mb-1">
                        <div class="flabel">{{trans('no-risky-actions-needed',177,{function_name:lowerCase(cApplicationData.function_name)})}}</div>
                    </b-col>
                </b-row>
            </b-card>

            <div v-for="item in cApplicationData.competences" :key="item.id_competence_const_group">
                <b-card class="mb-3" v-if="item.selected == 1">
                    <app-row-left-label :slots="[6,6]">
                        <template v-slot:col-1>
                            <label class="app-input-top-label">{{item.competence_group_name}}</label>
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">{{trans('enter-expiration-date',184)}}</label>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" v-for="competence in item.competences" :key="competence.id_competence_const_type">
                        <template v-slot:col-1>
                            <app-check-box :id="'checkbox_' + competence.id_competence_const_type.toString()" v-model="competence.selected" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="competence.competence_name" v-on:change="changeSelected(competence)" :minHeight="true" />
                        </template>
                        <template v-slot:col-2>
                            <app-date v-model="competence.competence_date" :validatorRules="competence.selected == '1' ? 'required|date-check' : '' " :validatorName="competence.competence_name" v-if="competence.selected == '1' " :validatorCustomMessage="{ required: trans('date-required',177) }" :disabledDatesPass="1" />
                        </template>
                    </app-row-left-label>

                </b-card>
            </div>

            <b-card class="mb-3" v-if="cApplicationData.competences != null">
                <app-row-left-label>
                    <app-check-box id="condition_privacy" v-model="cApplicationData.condition_privacy" :disabled="user_role == 3" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" validatorRules="required" :validatorCustomMessage="{ required: trans('pw-condition-privacy-requierd', 196) }" >
                        <template v-slot:custom-right-label>
                            {{trans('pw-condition-privacy-p1', 196)}} <b-link class="font-weight-600" target="_blank" href="#" @click="openPrivacy()">{{trans('pw-condition-privacy-p2', 196)}}</b-link> {{trans('pw-condition-privacy-p3', 196)}}
                        </template>  
                    </app-check-box>
                </app-row-left-label>

                <app-row-left-label>
                    <app-check-box id="condition_confirmation" v-model="cApplicationData.condition_confirmation" :disabled="user_role == 3" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('pw-condition-confirmation', 196)" validatorRules="required" :validatorCustomMessage="{ required: trans('pw-condition-confirmation-requierd', 196) }" /> 
                </app-row-left-label>

                <app-row-left-label>
                    <app-check-box id="condition_complete" v-model="cApplicationData.condition_complete" :disabled="user_role == 3" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('pw-complete-confirmation',196)" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error' , 180) }" />
                </app-row-left-label>
            </b-card>
        
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
        
    </div>
</template>

<script>

import axios from "@axios";

export default {
    components: {
    },

    props: ["applicationData", "showSuccessAlert", "showUnsuccessAlert", "loading"],

    created(){
        if(this.cApplicationData.condition_confirmation == false){
            this.cApplicationData.condition_confirmation = null;
        }
        if(this.cApplicationData.condition_privacy == false){
            this.cApplicationData.condition_privacy = null;
        }
        if(this.cApplicationData.condition_complete == false){
            this.cApplicationData.condition_complete = null;
        }

        if(this.cApplicationData.applicant_type == 3){
            this.array_functions = [3, 5, 10];
        }
    },

    watch:{
        showSuccessAlert(){
            this.successDismissCountDown = this.dismissSecs;
        },
        showUnsuccessAlert(){
            this.unsuccessDismissCountDown = this.dismissSecs;
        },

        "cApplicationData.condition_confirmation": {
            handler: function(val) {
                if(this.cApplicationData.condition_confirmation == false)
                    this.cApplicationData.condition_confirmation = null;
            },
        },

        "cApplicationData.condition_privacy": {
            handler: function(val) {
                if(this.cApplicationData.condition_privacy == false)
                    this.cApplicationData.condition_privacy = null;
            },
        },

        "cApplicationData.condition_complete": {
            handler: function(val) {
                if(this.cApplicationData.condition_complete == false)
                    this.cApplicationData.condition_complete = null;
            },
        },
    },

    computed: {
        cApplicationData: {
            get() { return this.applicationData },
            set() { this.$emit('update:applicationData', false) }, 
        },
    },

    data() {
        return {
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            radioSelected: 0,
            save_change_appication_type: false,
            loading_competences: false,
            competences_items: null,
            selected_all: this.applicationData.selected_all_competences,
            functions: null,
            array_functions: [8, 9, 10, 11],
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            position: [
                { value: 16, text: "VIG" },
                { value: 14, text: "Verpleegkundige MBO" },
                { value: 13, text: "Verpleegkundige HBO" },
                { value: 1000, text: "Andere functie" },
            ]            
        };
    },

    methods: {
        countDownChangedSuccess(successDismissCountDown) {
            this.successDismissCountDown = successDismissCountDown;
        },
        countDownChangedUnsuccess(unsuccessDismissCountDown){
            this.unsuccessDismissCountDown = unsuccessDismissCountDown;
        },
        changeFunction(){
            
            this.loading_competences = true;
            
            axios
                .post("people/profile/web-form/getCompetenceList", {
                    id_function_data_main: this.cApplicationData.function.value,
                    id_person_data_main: this.applicationData.id_person_data_main
                    })
                .then((res) => {              
                    this.cApplicationData.competences = res.data;
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_competences = false;
                });  
        },

        selectAll(){
            
            if(this.selected_all == 0){
                this.selected_all = 1;
                Object.keys(this.cApplicationData.competences).forEach((item) => {
                    this.cApplicationData.competences[item].selected = 1;
                });
                
            }else{
                this.selected_all = 0;
                Object.keys(this.cApplicationData.competences).forEach((item) => {
                    this.cApplicationData.competences[item].selected = 0;

                    this.cApplicationData.competences[item].competences.forEach(competence => {
                        competence.selected = 0;
                        competence.competence_date = null;
                    });

                });

            }
        },

        selectOne(item){
            if(item.selected == 1){
                item.selected = 0;
                this.selected_all = 0;

                item.competences.forEach(competence => {
                    competence.selected = 0;
                    competence.competence_date = null;
                });

            }else{
                item.selected = 1;
            }
            
        },

        changeSelected(item){
            if(item.selected == '0'){
                item.competence_date = null;
            }
                
        },

        openPrivacy(){
            window.open("https://happynurse.nl/privacy-statement", "_blank").focus();
        },
        
        lowerCase(text){
            if(text != null){
                return text.toLowerCase();
            }else{
                return "";
            }
            
        }
        
    },
};
</script>
<style>
.b-selected {
    filter: brightness(0) invert(1);
}

.app-local-btn{
    margin-top: 10px;
}</style>
