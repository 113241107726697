<template>
    <div>
        <app-row-left-label class="mb-2" :slots="[6,6]" :isMarginBetween="false">
            <template v-slot:col-1>
                <app-input :label="trans('enter-kvk',182)" type="integer" :disabled="disabled" :currentError="status >= 2" v-model="cApplicationData.kvk_number" validatorName="KVK nummer" validatorRules="required|digits:8" mask="########" @blur="checkKVK()"/>
                <app-input type="hidden"  v-show="cStatus" v-model="cStatus" validatorRules="required" />
            </template>
            <template v-slot:col-2>
                <app-row-left-label v-if="loading || status == 1" >            
                        <b-card no-body class="app-local-card-min-height app-card-info">
                            <div class="app-info-card-label" v-if="loading">
                                <b-spinner class="app-local-spinner"/>{{trans('checking',180)}}
                            </div>
                            <div class="app-info-card-label" v-if="!loading && status === 1">
                                <label class="flabel">{{trans('kvk-data',182)}}</label>
                                <div> {{ kvk.trade_name }} </div>
                                <div> {{ kvk.street }} {{kvk.city}}</div>
                            </div>
                        </b-card>
                </app-row-left-label>

                <app-row-left-label v-if="!loading && status == 2" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('wrong-number',180)}}</label>
                            </div>
                        </b-card>
                </app-row-left-label>

                <app-row-left-label v-if="!loading && status == 3" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div v-if=" clients_ids != null || persons_ids != null" class="app-info-card-label my-auto">
                                    <span class="app-popover-text" v-html="trans('kvk-already-used-error-p1',180)"></span>
                                    <span>&nbsp;</span>
                                    <span class="app-popover-text" v-if="clients_ids != null" v-html="trans('kvk-already-used-error-p2',180, {clients_names :clients_names, clients_ids : clients_ids})"></span>
                                    <span v-if="clients_ids != null && persons_ids != null">&nbsp;</span>
                                    <span class="app-popover-text" v-if="clients_ids != null && persons_ids != null" v-html="trans('and-word',182)"></span>
                                    <span v-if="clients_ids != null && persons_ids != null">&nbsp;</span>
                                    <span class="app-popover-text" v-if="persons_ids != null" v-html="trans('kvk-already-used-error-p3',180, {persons_names : persons_names, persons_ids : persons_ids})"></span>
                            </div>
                        </b-card>
                </app-row-left-label>
            </template>
        </app-row-left-label>     
    </div>
</template>
<script>
import axios from "@axios";

export default {

    components:{

    },

    props: {
        applicationData: {type: Object},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
    },

    created(){
        this.basic_kvk = JSON.parse(this.applicationData.kvk_data_main);
        this.showKVK();
    },

    computed: {
        cApplicationData: {
            get() { return this.applicationData },
            ste(value) { this.$emit("update:applicationData", value)}
        },
        cStatus: {
            get(){
                return this.status == 1 ? true : null;
            }
        }
    },

    data(){
        return{
            status: 0,
            basic_kvk: null,
            kvk: {
                trade_name: null,
                city: null,
                street: null
            },
            loading: false,
            clients_ids:null,
            clients_names: null,
            persons_ids: null,
            persons_names: null
        }
    },

    methods: {

        checkKVK() {
            if (this.cApplicationData.kvk_number === undefined || this.cApplicationData.kvk_number === null || this.cApplicationData.kvk_number.toString().length !== 8) {
                this.status = 0;
                this.kvk.trade_name = null;
                this.kvk.city = null;
                this.kvk.street = null;

                return;
            }
            else if(this.basic_kvk != null && this.basic_kvk.kvk_number == this.cApplicationData.kvk_number){
                this.status = 1;
                this.cApplicationData.kvk_data_main = JSON.stringify(this.basic_kvk);
                this.showKVK();
                return;
            } else {

            this.loading = true;

            this.kvk.trade_name = null;
            this.kvk.city = null;
            this.kvk.street = null;

            axios
                .get("core/kvk/check/" + this.cApplicationData.kvk_number)
                .then((res) => {
                    if(res.data == -1){
                        this.status = 2;
                        this.cApplicationData.kvk_data_main = null;
                    }else{
                        this.kvk = res.data;
                        this.cApplicationData.kvk_data_main = JSON.stringify(res.data);

                        if(this.kvk.persons_ids || this.kvk.clients_ids){
                            this.status = 3;
                        } else{
                            this.status = 1;
                        }

                        if(res.data.clients_ids|| res.data.persons_ids){
                            this.checked_kvk_number = "";
                            this.checked_kvk_key = "";
                            this.clients_ids = res.data.clients_ids; 
                            this.clients_names = res.data.clients_names;
                            this.persons_ids = res.data.persons_ids;
                            this.persons_names = res.data.persons_names;
                        }   

                    }
                })
                .catch((error) => {
                    console.error(`error during checking KVK: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                });
            }
        },

        showKVK() {
            if (this.cApplicationData.kvk_number !== undefined && this.cApplicationData.kvk_number !== null && this.cApplicationData.kvk_data_main !== null) {
                this.kvk = JSON.parse(this.applicationData.kvk_data_main);
                

                this.status = 1;
                this.loading = false;
            } else if (this.cApplicationData.kvk_number !== undefined && this.cApplicationData.kvk_number !== null && this.cApplicationData.kvk_data_main === null) {
                this.status = 2;
            } else {
                this.status = 0;
            }
        },

    }
    
}
</script>
<style scoped>
.app-local-card-min-height {
    min-height: 96px;
    padding: 1rem 1.5rem;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
    font-size: 11px;
}

.kvk-data-inf-large{
    font-size:14px
}

.kvk-data-inf-small{
    font-size:12px
}

.app-local-justify{
    text-align:justify;
}
</style>