<template>
    <div>
                
            <app-row-left-label :slots="[4,2,6]" class="mb-3">
                <template v-slot:col-1>
                    <app-input :label="trans('first-name',182)" v-model="cApplicationData.person_reference.first_name" validatorRules="required" validatorName="Voornaam"  mask="letters-20" @change="checkReference()"/>
                </template>

                <template v-slot:col-2>
                    <app-input v-model="cApplicationData.person_reference.inbetween_name" :label="trans('inbetween-name',182)" mask="lettersE1-10" />
                </template>

                <template v-slot:col-3>
                    <app-input v-model="cApplicationData.person_reference.last_name" :label="trans('last-name',182)" validatorRules="required" validatorName="Achternaam" mask="lettersE1-30" @change="checkReference()"/>
                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[6,6]" class="mb-3">
                <template v-slot:col-1>
                    <app-input v-model="cApplicationData.person_reference.function" :label="trans('function',3)" validatorRules="required" validatorName="Functie" @change="checkReference()"/>
                </template>

                <template v-slot:col-2>
                    <app-input v-model="cApplicationData.person_reference.organisation" :label="trans('organization',169)" validatorRules="required" validatorName="Organisatie" @change="checkReference()"/>
                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[6,6]" class="mb-3">
                <template v-slot:col-1>
                    <app-email :email.sync="cApplicationData.person_reference.email"  :label="trans('pw-reference-email',196)" validatorRules="required" validatorName="Email" customSpinnerClass="app-local-input-spinner"/>
                </template>

                <template v-slot:col-2>
                    <label class="flabel mb-0"> Vul hier je telefoonnummer in </label>
                    <app-phone :phone.sync="cApplicationData.person_reference.phone_number" :prefix.sync="cApplicationData.person_reference.phone_prefix" :isRequired="true" />
                </template>  
            </app-row-left-label>

    </div>
    
</template>
<script>

import AppEmail from '@core/components/AppEmail.vue';
import AppPhone from '@core/components/AppPhone.vue';
export default {

    props:{
        applicationData: Object,
        refresh_reference_error: {}
    },

    components: {
        AppEmail,   
        AppPhone
    },

    created() {
        this.cApplicationData.person_reference.phone_prefix = this.cApplicationData.person_reference.phone_prefix != null ? this.cApplicationData.person_reference.phone_prefix :  "+31";
    },

    computed: {
        cApplicationData:{
            get() { return this.applicationData },
            set(value) { this.$emit("update:applicationData", value) }
        },

        cRefreshReferenceError:{
            get() { return this.refresh_reference_error },
            set(value) { this.$emit("update:refresh_reference_error", value) }
        },
    },

    watch: {
        "cApplicationData.person_reference.phone_number": {
            handler: function(val) {
                if(this.cApplicationData.person_reference.phone_number.length >= 9){
                    this.checkReference()
                }
            },
            deep:true,
        },

        "cApplicationData.person_reference.email": {
            handler: function(val) {
                this.checkReference()
            },
            deep:true,
        },
    },

    data(){
        return{

        }
    },

    methods: {
        checkReference(){
            if(this.cApplicationData.person_reference.first_name != null && this.cApplicationData.person_reference.first_name != '' && this.cApplicationData.person_reference.last_name != null && 
                this.cApplicationData.person_reference.last_name != '' && this.cApplicationData.person_reference.function != null && this.cApplicationData.person_reference.function != '' && 
                this.cApplicationData.person_reference.organisation != null && this.cApplicationData.person_reference.organisation != '' && this.cApplicationData.person_reference.email != null && 
                this.cApplicationData.person_reference.email != '' && this.cApplicationData.person_reference.phone_number != null && this.cApplicationData.person_reference.phone_number != '' &&
                /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(this.cApplicationData.person_reference.email)){
                    this.cRefreshReferenceError++;
            }
        }
    }
    
}
</script>
<style scoped>

</style>