<template>
    <div>
        <div class="app-card-profile-title mb-3">
            {{trans('pw-retirement-history',196)}}
            <img tabindex="0" src="@/assets/images/icons/info-button.svg" height="18px" style="vertical-align: text-top;" class="app-cursor-pointer" @click="showRetirementInfo" />
        </div>     
        
        <b-row>
            <b-col cols="6">
                <app-radio-group v-model="cApplicationData.is_pension" :label="trans('pw-is-pension-question',196)" :options="pension_options" :validatorCustomMessage="{ required: trans('field-required-error', 180) }" validatorRules="required" />
            </b-col>
            <b-col cols="6">
                <app-radio-group v-if="cApplicationData.is_pension == 1" v-model="cApplicationData.pension_type" :label="trans('pw-pension-type-question',196)" :options="pension_types" :validatorCustomMessage="{ required: trans('field-required-error', 180) }" validatorRules="required" />
                <app-input v-if="cApplicationData.is_pension == 1 && cApplicationData.pension_type == 1" v-model="cApplicationData.previous_worked_weeks" :label="trans('pw-number-of-weeks-accrued',196)" validatorRules="required|correct-pension-count" :validatorName="trans('pw-number-of-weeks-accrued',196)" mask="##" class="mt-2"/>
             </b-col>
        </b-row>
    <people-applicants-profile-wizard-pension-info v-if="mPensionInfo.show" :show.sync="mPensionInfo.show"/>
    </div>
</template>
<script>
import PeopleApplicantsProfileWizardPensionInfo from './PeopleApplicantsProfileWizardPensionInfo.vue';

export default {

    props: ["applicationData"],

    components: {
        PeopleApplicantsProfileWizardPensionInfo
    },

    computed: {
        cApplicationData:{
            get() { return this.applicationData },
            set(value) { this.$emit("update:applicationData", value) }
        },
    },

    data(){
        return{ 
            mPensionInfo: { show: false, args: {}, result: 0, return: null },
            pension_options: [
                { value: 1, text: this.trans('yes-option',3)},
                { value: 0, text: this.trans('no-option',3)  },
            ],
            pension_types: [
                { value: 1, text: this.trans('pw-basic-scheme',196) },
                { value: 2, text: this.trans('pw-plus-scheme',196)  },
            ],
        }
    },

    methods: {
        showRetirementInfo() {
            this.mPensionInfo.show = true;
        },
    }
    
}
</script>
<style scoped>

</style>