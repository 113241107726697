<template>
    <div>
        <b-form @submit.prevent>
            <b-row> 
                <b-col>                    
                    <b-row v-if="applicationData.document_liability.file_front">
                        <b-col md="8" xl="8">
                            <span class="font-weight-bolder"> {{trans('pw-professional-liability',196)}}: </span>
                            <span>{{applicationData.document_liability.file_front.file_name_user}}</span>
                            <span id="tooltip-expire-date"> | {{applicationData.document_liability.file_front.date_to}}</span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <span class="text-right font-weight-bolder"> {{ applicationData.document_liability.file_front.size }} MB </span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-link href="#" @click="deleteFile(applicationData.document_liability.file_front.id_document_data_main, 6)">
                                 <span class="text-right" v-if="!fDeletingFront">{{trans('remove',175)}}</span>
                                 <b-spinner small v-if="fDeletingFront" />
                            </b-link>
                        </b-col>        
                    </b-row>
                     <b-row v-if="applicationData.document_liability.file_back">
                        <b-col md="8" xl="8">
                            <span class="font-weight-bolder">{{trans('pw-receipt',196)}} </span>
                            <span>{{applicationData.document_liability.file_back.file_name_user}}</span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <span class="text-right font-weight-bolder"> {{ applicationData.document_liability.file_back.size }} MB </span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-link href="#" @click="deleteFile(applicationData.document_liability.file_back.id_document_data_main, 40)">
                                 <span class="text-right" v-if="!fDeletingBack">{{trans('remove',175)}}</span>
                                 <b-spinner small v-if="fDeletingBack" />
                            </b-link>
                        </b-col>        
                    </b-row>  
                </b-col>
            </b-row>    
            <b-row v-bind:class="{ 'mt-2': applicationData.document_liability.file_front || applicationData.document_liability.file_back }">
                <b-col v-if="!applicationData.document_liability.file_front">                  
                        <div class="image-upload-wrap">
                            <input class="file-upload-input" type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" v-on:change="addFile($event, 6)"/>
                            <div class="drag-text">
                                <img src="@/assets/images/icons/download-cloud.svg"  height="40px" v-if="!fUploadingFront"/>
                                <b-spinner v-if="fUploadingFront"  style="margin-top: 4px; margin-bottom:6px"/>
                                <div>
                                     <small class="text-muted">{{ trans('pw-liability-document-p1',196) }} <span style="color: #17407a; font-weight:700"> {{ trans('pw-liability-document-p2',196) }} </span> {{ trans('pw-liability-document-p3',196) }}</small>
                                </div>                                
                            </div>
                        </div>                    
                </b-col>
                <b-col v-if="!applicationData.document_liability.file_back">                  
                        <div class="image-upload-wrap">
                            <input class="file-upload-input" type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" v-on:change="addFile($event, 40)"/>
                            <div class="drag-text">                                
                                <img src="@/assets/images/icons/download-cloud.svg"  height="40px"  v-if="!fUploadingBack"/>
                                <b-spinner v-if="fUploadingBack"  style="margin-top: 4px; margin-bottom:6px"/>
                                 <div>
                                     <small class="text-muted">{{ trans('pw-liability-document-p1',196) }} <span style="color: #17407a; font-weight:700"> {{ trans('pw-liability-document-second-p2',196) }} </span> {{ trans('pw-liability-document-p3',196) }}</small>
                                </div> 
                            </div>
                        </div>
                </b-col>
            </b-row>
        </b-form>
        <b-modal ref="datepicker-modal" centered okVariant="primary" okTitle="OK" :title="trans('set-expiration-date',8)" size="sm" ok-only @ok="handleDatepickerOk">
            <b-row>
                <b-col>
                    <datepicker v-model="selectedDate" :inline="true" :language="languages[language]" :disabled-dates="disabledDates"></datepicker>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import { BSpinner} from "bootstrap-vue";
import axios from "@axios";
import moment from "moment";
import * as lang from "vuejs-datepicker/src/locale";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

export default {
    components: {
        BSpinner,
        Datepicker
    },

    props: ["applicationData"],

    computed:{
        cApplicationData: {
            get() { return this.applicationData },
            set() { this.$emit('update:applicationData', false) }, 
        },
    },

    data() {
        return {
            formData: new FormData(),
            fUploadingFront: false,
            fUploadingBack: false,
            fDeletingFront: false,
            fDeletingBack: false,
            selectedDate: "",
            language: "nl",
            languages: lang,
            id_document_const_type: null,
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
        };
    },
    
    methods: {

        openDatepickerModal(){
            this.$refs["datepicker-modal"].show();
        },

        handleDatepickerOk(){
       
            if(moment(this.selectedDate).format("yyyy-MM-DD") == "Invalid date"){
                this.formData.append("date_to", moment().format("yyyy-MM-DD"));
            }else{
                this.formData.append("date_to", moment(this.selectedDate).format("yyyy-MM-DD"));
            }

            this.sendFile();
        },

        sendFile(){
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            if (this.id_document_const_type === 6) {
                this.fUploadingFront = true;
            } else if(this.id_document_const_type === 40) {
                this.fUploadingBack = true;
            }

            axios
                .post("people/profile/web-form/liability/addFile", this.formData, config)
                .then((response) => {                             
                            
                    this.parseResponse(response);
        
                }).catch(function(error) {

                    //console.log(error);
                }).finally(() => {
                    this.formData = new FormData();
                    if (this.id_document_const_type === 6) {
                        this.fUploadingFront = false;
                    } else if(this.id_document_const_type === 40) {
                        this.fUploadingBack = false;
                    } 
                    
                });

        },

        addFile(e, id_document_const_type) {

            this.id_document_const_type = id_document_const_type;

            var file = e.target.files[0];

            if(!file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*')) {
                alert(this.trans('only-jpg-png',180));
                file = "";
                return;
            }

            this.formData = new FormData();
            this.formData.append("id_person_data_main", this.applicationData.id_person_data_main);
            this.formData.append("file", file);
            this.formData.append("file_name", file.name);
            this.formData.append("id_document_const_type", id_document_const_type);
            this.formData.append("file_size", file.size);

           

            if(id_document_const_type == 6){
                this.openDatepickerModal();
            }else{
                this.sendFile();
            }

           
        },

        deleteFile: function(id_document_data_main, id_document_const_type) {
            
            if (id_document_const_type === 6) {
                this.fDeletingFront = true;
            } else if(id_document_const_type === 40) {
                this.fDeletingBack = true;
            }

             let formData = new FormData();
            formData.append("id_person_data_main", this.applicationData.id_person_data_main);
            formData.append("id_document_data_main", id_document_data_main);
            formData.append("id_document_const_type", id_document_const_type);
            
            axios
                .post("people/profile/web-form/liability/deleteFile", formData)
                .then((response) => {
                    this.parseResponse(response);

                    if (id_document_const_type === 6) {
                        this.fDeletingFront = false;
                    } else if(id_document_const_type === 40) {
                        this.fDeletingBack = false;
                    }
                })
                .catch((error) => {

                    if (id_document_const_type === 6) {
                        this.fDeletingFront = false;
                    } else if(id_document_const_type === 40) {
                        this.fDeletingBack = false;
                    }

                    console.error(`error during deleting files: ${error}`);
                });
        },

        parseResponse: function(response) {
            if (response.data != undefined && response.data != null) {
                this.cApplicationData.document_liability.file_front = response.data.file_front;
                this.cApplicationData.document_liability.file_back = response.data.file_back;
                this.cApplicationData.document_liability.status = response.data.status;
            }
        }
    },    

};
</script>

<style scoped>
.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}
.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.fileContainer {
    font-size: 12px;
    border: none;
    padding: 0 0 10px 0;
}
.fileContainer:hover {
    background-color: transparent;
}
.deleteButton {
    color: #71e6ee;
    cursor: pointer;
    float: left;
}
.files {
    width: 43%;
}
.loading {
    margin-left: 40%;
}
.uploadIcon {
    width: 13%;
}

</style>
