<template>
    <div>
       
        <app-collapse accordion type="margin" class="mb-3">
            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" :title="trans('id-label',184)" :id="'item1'">
                    <app-row-left-label class="mb-3" :slots="[6,6]">
                        <template v-slot:col-1>
                            <app-radio-group :label="trans('pw-identification-type',196)" :options="document_type" v-model="cApplicationData.document_type" validatorName="DocumentType" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" @change="checkId()"/>
                            <small class="text-muted">{{trans('pw-identity-issued-by-dutch',196)}}</small><br/>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false" class="mb-3">
                        <template v-slot:col-1>
                            <app-input :label="trans('pw-citizen-service-number',196)" v-model="cApplicationData.bsn_number" validatorName="BSN nummer" validatorRules="required|sofi-number" v-mask="'#########'" @change="checkId()"/>
                        </template>
                    </app-row-left-label>
                    
                    <people-applicants-profile-wizard-document-id-component :applicationData="applicationData" :refresh_id_error.sync="refresh_id_error"/>
                </app-collapse-item>

                <validation-provider :key="refresh_id_error" :rules="(applicationData.document_id.status == 1 && applicationData.document_type != null && applicationData.bsn_number != null && applicationData.bsn_number != '') ? '' : 'required'" #default="{ errors }" :name="trans('id-label',184)" :vid="'id_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-bank-details',184)" :id="'item6'">
                    <people-applicants-profile-wizard-bank-details-component :applicationData="applicationData" :refresh_bank_error.sync="refresh_bank_error"/>
                </app-collapse-item>

                <validation-provider :key="refresh_bank_error" #default="{ errors }" :name="trans('fp-bank-details',184)" :vid="'bank_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3" v-if="applicationData.applicant_type == 1">
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item2'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pw-diplomas',196)}}</span>
                        <span class="text-muted ml-1" v-if="[2,21,14,12].includes(applicationData.id_function_data_main)"> {{trans('pw-optional',196)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="3" :maxFilesNumber="3" :applicationData="applicationData" :document="applicationData.document_diploma" />
                </app-collapse-item>
                <validation-provider :rules="(applicationData.document_diploma.status == 1 || [2,21,14,12].includes(applicationData.id_function_data_main)) ? '' : 'required'" #default="{ errors }" :name="trans('pw-diplomas',196)">
                    <small class="text-danger ml-1" v-if="applicationData.document_diploma.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3" v-if="applicationData.applicant_type == 3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('fw-proof-of-registration',196)" :id="'item2'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="44" :maxFilesNumber="1" :applicationData="applicationData" :document="applicationData.document_bvi" />
                </app-collapse-item>
                <validation-provider :rules="applicationData.document_bvi.status == 1 ? '' : 'required'" #default="{ errors }" :name="trans('fw-proof-of-registration',196)">
                    <small class="text-danger ml-1" v-if="applicationData.document_bvi.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pw-vog',196)" :id="'item3'">
                    <label class="app-local-label">{{trans('pw-vog-description',196)}}</label>
                    <app-radio-group v-model="cApplicationData.is_vog_document" :options="vog_option" :stacked="true" class="app-local-radio-vog mt-3"/>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="9" class="mt-4" v-if="cApplicationData.is_vog_document != null && cApplicationData.is_vog_document == 1" :maxFilesNumber="1" :applicationData="applicationData" :document="applicationData.document_verklaring" />
                </app-collapse-item>
                 <validation-provider :rules="applicationData.is_vog_document != null ? '' : 'required'" v-if="cApplicationData.is_vog_document == null"  #default="{ errors }" :custom-messages="{ required: trans('required-message',180) }">
                    <small class="text-danger ml-1" v-if="applicationData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider :rules="(applicationData.document_verklaring.status == 1 || cApplicationData.is_vog_document == null || cApplicationData.is_vog_document != 1)  ? '' : 'required'" v-if="cApplicationData.is_vog_document != 0"   #default="{ errors }" :name="trans('pw-vog',196)">
                    <small class="text-danger ml-1" v-if="applicationData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
               
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('cv',182)" :id="'item4'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="7" :maxFilesNumber="1" :applicationData="applicationData" :document="applicationData.document_cv" accept_files="application/msword, application/pdf" />
                </app-collapse-item>
                <validation-provider :rules="applicationData.document_cv.status == 1 ? '' : 'required'" #default="{ errors }" :name="trans('cv',182)">
                    <small class="text-danger ml-1" v-if="applicationData.document_cv.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-reference',172)" :id="'item7'">
                    <people-applicants-profile-wizard-reference-component :applicationData="applicationData" :refresh_reference_error.sync="refresh_reference_error"/>
                </app-collapse-item>
                <validation-provider :key="refresh_reference_error" #default="{ errors }" :name="trans('fp-reference',172)" :vid="'reference_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>
            
            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je optionele documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" :id="'item5'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pw-certificates',196)}}</span>
                        <span class="text-muted ml-1"> {{trans('pw-optional',196)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="10" :maxFilesNumber="3" :applicationData="applicationData" :document="applicationData.document_certificate" />
                </app-collapse-item>
                <small class="text-danger ml-1"></small>
            </b-card>
        </app-collapse>

        <b-card class="mb-3">
            <div class="app-card-profile-title mb-3">
                {{trans('apply-payroll-credit',184)}}
                <img id="popover-target-1" tabindex="0" src="@/assets/images/icons/info-button.svg" height="18px" style="vertical-align: text-top;" class="cursor-pointer" @click="showLoonInfo" />
            </div>
            <app-radio-group v-model="cApplicationData.id_payroll_taxes" :label="trans('pw-indicate-payroll-tax-credit',196)" :stacked="true" :options="payroll_taxes" validatorName="PayrollTaxes" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
        </b-card>

        <b-card class="mb-3">
            <people-applicants-profile-wizard-pension-component :applicationData="applicationData" />
        </b-card>

        <b-popover target="popover-target-1" triggers="focus" no-fade>
            <template #title>
                {{trans('apply-payroll-credit',184)}}
            </template>
            <p class="app-popover-text"><span class="font-weight-600">{{trans('yes-with-credit',184)}}</span> {{trans('pw-yes-option-one-hint',196)}}</p>
            <p class="app-popover-text"><span class="font-weight-600 app-popover-text">{{trans('yes-without-credit',184)}}</span> {{trans('pw-yes-option-two-hint',196)}}</p>
            <p class="app-popover-text"><span class="font-weight-600 app-popover-text">{{trans('no-option',3)}}</span> {{trans('pw-no-option-hint',196)}}</p>
            <p class="app-popover-text"><span class="font-weight-600 app-popover-text">{{trans('anonymous-rate',184)}}</span> {{trans('pw-anonymous-option-hint',196)}}</p>
        </b-popover>
    </div>
</template>
<script>
import { BPopover } from "bootstrap-vue";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import PeopleApplicantsProfileWizardDocumentIdComponent from './PeopleApplicantsProfileWizardDocumentIdComponent.vue';
import PeopleApplicantsProfileWizardBankDetailsComponent from './PeopleApplicantsProfileWizardBankDetailsComponent.vue';
import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue';
import PeopleApplicantsProfileWizardReferenceComponent from './PeopleApplicantsProfileWizardReferenceComponent.vue';
import PeopleApplicantsProfileWizardPensionComponent from './PeopleApplicantsProfileWizardPensionComponent.vue';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BPopover,
        PeopleApplicantsProfileWizardDocumentIdComponent,
        PeopleApplicantsProfileWizardDocumentComponent,
        PeopleApplicantsProfileWizardBankDetailsComponent,
        PeopleApplicantsProfileWizardReferenceComponent,
        PeopleApplicantsProfileWizardPensionComponent
    },

    props: ["applicationData"],

    computed: {
        cApplicationData:{
            get() { return this.applicationData },
            set(value) { this.$emit("update:applicationData", value) }
        },
    },

    data() {
        return {
            document_type:[
                { value: 1, text: this.trans('passport',8) },
                { value: 2, text: this.trans('id-card',8) },
                { value: 3, text: this.trans('residence-document',8) },
            ],
            payroll_taxes: [
                { value: 1, text: this.trans('yes-with-credit',184) },
                { value: 2, text: this.trans('yes-without-credit',184) },
                { value: 3, text: this.trans('no-option',3) },
                { value: 4, text: this.trans('anonymous-rate',184) },
            ],
            vog_option: [
                { value: 1, htmltext: this.trans('pw-add-vog',196)},
                { value: 0, htmltext: this.trans('pw-apply-for-vog',196)  },
            ],
            refresh_bank_error: 0,
            refresh_id_error: 0,
            refresh_reference_error: 0,
        };
    },

    methods: {
        showLoonInfo() {
            this.$root.$emit("bv::show::popover", "popover-target-1");
        },

        checkId(){
            if(this.cApplicationData.bsn_number != null && this.cApplicationData.document_type != null && this.cApplicationData.bsn_number != '' && this.cApplicationData.document_type != ''){
                var testnumber = this.cApplicationData.bsn_number;
                if (testnumber.toString().length != 9) {
                    return;
                } else {
                    var sum = 0;
                    var c = 0;
                    for (var i = 0; i <= 7; i++) {
                        c = testnumber.toString().substring(i, i + 1);
                        sum = sum + (9 - i) * c;
                    }
                    c = testnumber.toString().substring(8, 9);
                    c = c * -1;
                    sum = sum + c;
                    if (sum % 11 == 0) {
                        this.refresh_id_error++;
                    }
                }
            }
        }
    },
};
</script>

<style>
.popover {
    max-width: 360px;
}

[class*="collapse-"] .card .card-body{
    padding: 1.5rem;   
}

.app-local-radio-vog .custom-control-label {
    height: auto !important
}

.app-local-label{
    font-size: 13px;
    color: #174066;
}
</style>
