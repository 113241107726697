<template>
    <div>
        <b-card class="mb-3">
            <div class="app-card-profile-title mb-1">{{trans('pw-zzp-data',196)}}</div>
            <div class="mx-3 mt-3">
           
                <app-wizard-kvk :applicationData.sync="applicationData"/>                

                <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                    <template v-slot:col-1>
                        <div class="app-input-top-label mt-2">{{trans('pw-btw',196)}} <label><img id="popover-target-1" tabindex="0" src="@/assets/images/icons/info-button.svg" height="18px" style="vertical-align: text-top; margin-top: -1px;" class="cursor-pointer ml-1" /> </label> </div>
                    </template>
                    <template v-slot:col-2>
                        <app-input v-model="cApplicationData.btw_number" validatorName="BTW nummer" validatorRules="btw-number" mask="BTW" :uppercase="true" /> 
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                    <template v-slot:col-1>
                        <!--<div class="app-input-top-label mt-2">{{trans('pw-wtza-registration-number',196)}}</div>-->
                        <div class="app-input-top-label mt-2">{{trans('fp-registered-in-lrza-register',172)}}</div>
                    </template>
                    <template v-slot:col-2>
                        <!--<app-input  v-model="cApplicationData.wtza_number" validatorName="Registratienummer WTZA" validatorRules="digits:8" type="integer_08" />-->
                        <app-check-box id="lrza_checkbox" v-model="cApplicationData.lrza" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error' , 180) }" />
                    </template>
                </app-row-left-label>

                <b-popover target="popover-target-1" triggers="focus" no-fade>
                    <template #title>
                        {{trans('information',182)}}
                    </template>
                    <p class="app-popover-text" v-html="trans('pw-btw-hint',196)"></p>
                </b-popover>
            </div>

        </b-card>

        <app-collapse accordion type="margin" class="mb-3">

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="Bankgegevens" :id="'item8'">
                    <people-applicants-profile-wizard-bank-details-component :applicationData="applicationData" :refresh_bank_error.sync="refresh_bank_error"/>
                </app-collapse-item>

                <validation-provider #default="{ errors }" name="Bankgegevens" :vid="'bank_data'" :key="refresh_bank_error">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>


            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item1'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pw-diplomas',196)}}</span>
                        <span class="text-muted ml-1" v-if="[2,21,14,12].includes(applicationData.id_function_data_main)"> {{trans('pw-optional',196)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="3" :maxFilesNumber="3" :applicationData="applicationData" :document="applicationData.document_diploma" />
                </app-collapse-item>
                <validation-provider :rules="(applicationData.document_diploma.status == 1 || [2,21,14,12].includes(applicationData.id_function_data_main)) ? '' : 'required'" #default="{ errors }" name="Diploma('s)">
                    <small class="text-danger ml-1" v-if="applicationData.document_diploma.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;"  :title="trans('pw-vog',196)" :id="'item2'">
                    <small class="text-muted">{{trans('pw-vog-description',196)}}</small>
                    <app-radio-group v-model="cApplicationData.is_vog_document" :options="vog_option" :stacked="true" class="app-local-radio-vog mt-3"/>
                    <people-applicants-profile-wizard-document-component  :id_document_const_type="9" v-if="cApplicationData.is_vog_document != null && cApplicationData.is_vog_document == 1" :maxFilesNumber="1" class="mt-4" :applicationData="applicationData" :document="applicationData.document_verklaring" />
                </app-collapse-item>
                 <validation-provider :rules="applicationData.is_vog_document != null ? '' : 'required'" v-if="cApplicationData.is_vog_document == null"  #default="{ errors }" :custom-messages="{ required: trans('required-message',180) }">
                    <small class="text-danger ml-1" v-if="applicationData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider :rules="(applicationData.document_verklaring.status == 1 || cApplicationData.is_vog_document == null || cApplicationData.is_vog_document != 1)  ? '' : 'required'" v-if="cApplicationData.is_vog_document != 0"   #default="{ errors }" name="Verklaring omtrent gedrag">
                    <small class="text-danger ml-1" v-if="applicationData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            
            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pw-dispute-committee',196)" :id="'item4'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="5" :maxFilesNumber="3" :applicationData="applicationData" :document="applicationData.document_disputes" />
                </app-collapse-item>
                <validation-provider :rules="applicationData.document_disputes.status == 1 ? '' : 'required'" #default="{ errors }" name="Geschillencommissie">
                    <small class="text-danger ml-1" v-if="applicationData.document_disputes.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pw-professional-liability',196)" :id="'item5'">
                    <!--<people-applicants-profile-wizard-document-liability-component :applicationData="applicationData" />-->
                    <people-applicants-profile-wizard-document-component :id_document_const_type="6" :maxFilesNumber="1" :applicationData="applicationData" :document="applicationData.document_liability" />
                </app-collapse-item>
                <validation-provider :rules="applicationData.document_liability.status == 1 ? '' : 'required'" #default="{ errors }" name="Beroepsaansprakelijkheid">
                    <small class="text-danger ml-1" v-if="applicationData.document_liability.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="CV" :id="'item6'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="7" :maxFilesNumber="1" :applicationData="applicationData" :document="applicationData.document_cv" accept_files="application/msword, application/pdf" />
                </app-collapse-item>
                <validation-provider :rules="applicationData.document_cv.status == 1 ? '' : 'required'" #default="{ errors }" name="CV">
                    <small class="text-danger ml-1" v-if="applicationData.document_cv.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-reference',172)" :id="'item10'">
                    <people-applicants-profile-wizard-reference-component :applicationData="applicationData" :refresh_reference_error.sync="refresh_reference_error"/>
                </app-collapse-item>
                <validation-provider :key="refresh_reference_error" #default="{ errors }" name="Referentie" :vid="'reference_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :id="'item3'">
                     <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pw-quality-register',196)}}</span>
                        <span class="text-muted ml-1"> {{trans('pw-optional',196)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="4" :maxFilesNumber="3" :applicationData="applicationData" :document="applicationData.document_quality" />
                </app-collapse-item>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je optionele documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" :id="'item7'">
                     <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pw-certificates',196)}}</span>
                        <span class="text-muted ml-1"> {{trans('pw-optional',196)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="10" :maxFilesNumber="3" :applicationData="applicationData" :document="applicationData.document_certificate" />
                </app-collapse-item>
                <small class="text-danger ml-1"></small>
            </b-card>
        </app-collapse>
    </div>
</template>
<script>
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import { ValidationProvider } from "vee-validate";
import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue';
import PeopleApplicantsProfileWizardDocumentLiabilityComponent from './PeopleApplicantsProfileWizardDocumentLiabilityComponent.vue';
import PeopleApplicantsProfileWizardBankDetailsComponent from './PeopleApplicantsProfileWizardBankDetailsComponent.vue';
import PeopleApplicantsProfileWizardReferenceComponent from './PeopleApplicantsProfileWizardReferenceComponent.vue';
import AppWizardKvk from '@core/components/Kvk/AppWizardKvk.vue';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        ValidationProvider,
        PeopleApplicantsProfileWizardDocumentComponent,
        PeopleApplicantsProfileWizardDocumentLiabilityComponent,
        PeopleApplicantsProfileWizardBankDetailsComponent,
        PeopleApplicantsProfileWizardReferenceComponent,
        AppWizardKvk
    },

    props: ["applicationData", "substeps"],

    created() {
        if(this.cApplicationData.lrza == '0'){
            this.cApplicationData.lrza = null;
        }
    },

    watch:{
        "cApplicationData.lrza": {
            handler: function(val) {
                if(this.cApplicationData.lrza == '0')
                    this.cApplicationData.lrza = null;
            },
        },
    },

    computed: {
        cApplicationData:{
            get() { return this.applicationData },
            set(value) { this.$emit("update:applicationData", value) }
        }
    },
    
    data() {
        return {
            option: [
                { value: 1, text: this.trans('yes-option',3) },
                { value: 0, text: this.trans('no-option',3) },
            ],
            vog_option: [
                { value: 1, htmltext: this.trans('pw-add-vog',196)},
                { value: 0, htmltext: this.trans('pw-apply-for-vog',196)  },
            ],
            document_type:[
                { value: 1, text: this.trans('passport',8) },
                { value: 2, text: this.trans('id-card',8) },
                { value: 3, text: this.trans('residence-document',8) },
            ],
            refresh_bank_error: 0,
            refresh_reference_error: 0,
        };
    },

    methods: {
    }
    
};
</script>

<style>
[class*="collapse-"] .card .card-body{
    padding: 1.5rem;   
}

.app-local-radio-vog .custom-control-label {
    height: auto !important
}
</style>
