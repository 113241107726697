<template>
  <div>

    <b-row> 
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

        <b-skeleton-wrapper :loading="show_skeleton">
            <template #loading>
                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="30%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px"></b-skeleton>
                </b-card>

                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="40%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px"></b-skeleton>
                </b-card>

                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="75%" height="40px"></b-skeleton>
                </b-card>

                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="60%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="50%" height="50px"></b-skeleton>
                </b-card>

            </template>

            <div v-if="this.application_data !== null">

                <div class="mt-3 mb-1 d-flex align-items-center" v-if="step > 0">
                    <b-link v-if="step > 1" href="#" @click="goBack" class="d-flex align-items-center"><b-icon icon="arrow-left" font-scale="1" class="mr-3"></b-icon>{{trans('previous-step',175)}}</b-link><span class="mr-2 ml-2" v-if="step > 1"> | </span> 
                    <b-link href="#" @click="goPersonCard">{{trans('pw-back-to-applicant',196)}}</b-link>
                </div>

                <ValidationObserver ref="form">
                    <form @submit.prevent="onSubmit">
                        <content-step-1 :applicationData="application_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-if="step === 1" />
                        <content-step-2 :applicationData.sync="application_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" :loadingPartial="loading_partial" v-else-if="step === 2" />
                        <content-step-3 :applicationData="application_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-else-if="step === 3" />
                    </form>
                </ValidationObserver>

                <div v-if="step !== 4" class="mt-4 mb-3" >
                    <app-button @click="saveStep(0)" type="cancel" :disabled="loading == 1"> {{trans('save',175)}} </app-button>
                    <app-button type="primary" @click="nextStep()" :disabled="loading == true || (step == 3 && user_role == 3)" :loading="loading">{{trans('forward',175)}}</app-button>
                </div>

            </div>            
            

        </b-skeleton-wrapper>

        </b-col>
    </b-row> 
    
  </div>
</template>

<script>

import axios from "@axios";
import ContentStep1 from "./Step1/PeopleApplicantsProfileWizardStep1.vue";
import ContentStep2 from "./Step2/PeopleApplicantsProfileWizardStep2.vue";
import ContentStep3 from "./Step3/PeopleApplicantsProfileWizardStep3.vue";
import { ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: { 
       ContentStep1,
       ContentStep2,
       ContentStep3,
       ValidationObserver
    },

    created() {
        this.getData();
    },

    data() {
        return {
            step: 1,
            id_person_data_main: this.$route.params.id_person_data_main,
            step_title: "Persoonlijke informatie",
            application_data: null,
            show_skeleton: true,
            loading: false,
            loading_next_step: false,
            loading_partial: false,
            show_success_alert: false,
            show_unsuccess_alert: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {
        getData: function(){
            this.loading = true;
            axios
                .get("people/profile/web-form/getData/" + this.id_person_data_main + "/" + this.step)
                .then((res) => {
                    this.application_data = res.data;
                    this.show_skeleton = false;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.show_skeleton = false;
                });

        },

        saveStep(go_forward){
            
            if(go_forward == 1){


                if(this.step === 1){// krok pierwszy sprawdzanie poprawnosci adresu i BIG
                    if(this.application_data.address.type === 1 && (this.application_data.address.city === null || this.application_data.address.city == "")
                                                                && (this.application_data.address.street === null || this.application_data.address.street == "")){
                        return false;
                    }
                    
                    if(this.application_data.big.is_big_number === 1 && this.application_data.big.big_data_main === null){
                        return false;
                    }

                }else if(this.step === 2){// krok drugi sprawdzenie poprawnosci KVK

                    if(this.application_data.applicant_type === undefined || this.application_data.applicant_type === null || this.application_data.applicant_type < 1){
                        return false;
                    }

                    if((this.application_data.applicant_type == 2 && this.application_data.kvk_data_main == null) || (this.application_data.applicant_type == 2 && this.application_data.kvk_data_main != null && JSON.parse(this.application_data.kvk_data_main).kvk_number != this.application_data.kvk_number)){
                        return false;
                    }

                }

                this.loading_next_step = true;
            }

            this.loading = true;

            let form_data = new FormData();

            if(this.step == 1){//zapisanie kroku 1 
                
                form_data.append("application_data", JSON.stringify(this.application_data));
                form_data.append("go_forward", go_forward);


                axios
                    .post("people/profile/web-form/saveStepOne",  form_data)
                    .then((res) => {
                        
                        if(res.data != 1){
                            this.application_data = res.data;
                            this.step = 2;
                        }

                        this.loading_next_step = false;
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;
                        
                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }

                        document.getElementById('app').scrollIntoView({
                                                                    behavior: "smooth",
                                                                    block: "start",
                                                                });
                        
                    });

            }else if(this.step == 2){//zapisanie kroku 2 

                
                form_data.append("go_forward", go_forward);
                form_data.append("application_data", JSON.stringify(this.application_data));
                
                axios
                    .post("people/profile/web-form/saveStepTwo",  form_data)
                    .then((res) => {
                        
                        if(res.data != 1){
                            this.application_data = res.data;
                            this.step = 3;
                        }

                        this.loading_next_step = false;
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;

                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }

                        document.getElementById('app').scrollIntoView({
                                                                    behavior: "smooth",
                                                                    block: "start",
                                                                });
                        
                    });

            }else if(this.step == 3){//zapisanie kroku 3

                form_data.append("go_forward", go_forward);
                form_data.append("application_data", JSON.stringify(this.application_data));


                axios
                    .post("people/profile/web-form/saveStepThree",  form_data)
                    .then((res) => {
                        
                        if(res.data == 2){
                            this.goPersonCard();
                        }

                        this.loading_next_step = false;
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;

                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }
                        
                    });

            }

            document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
            
        },

        nextStep(){
            
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.errors)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                return !error || !error.value || error.value.length > 0;
                            });

                        if (errors && errors.length > 0) {
                                errors.forEach(error => {
                                    if(error.key == 'IBAN'){
                                        this.$refs.form.setErrors({"bank_data":this.trans('invalid-iban',199)});

                                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                                    }
                                    if(error.key == 'BSN nummer'){
                                        this.$refs.form.setErrors({"id_data":this.trans('invalid-bsn',199)});

                                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                                    }


                                    if(error.key == 'Functie' || error.key == 'Organisatie' || error.key == 'Voornaam' || error.key == 'Achternaam' || error.key == 'Telefoonnummer' || error.key == 'Email'){
                                        this.$refs.form.setErrors({"reference_data":this.trans('pw-reference-required',196)});
                                    }

                                })

                            if(this.$refs.form.refs[errors[0]["key"]] != null){
                                this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            }else{
                                this.$refs.form.refs['Telefoonnummer'].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            }   
                        }
                    }, 100);

                    return false;
                }
                
                this.saveStep(1);
            });

        },

        goBack: function(){
            
            this.loading = true;

            axios
            .get("people/profile/web-form/getData/" + this.id_person_data_main + "/" + (this.step - 1))
            .then((res) => {
                
                this.application_data = res.data;
                this.step = this.step - 1;
            
            })
            .catch((error) => {
                console.error(`error during request: ${error}`);
                this.loading = false;
            })
            .finally(() =>{
                this.loading = false;
                document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
            });

        },

        goPersonCard: function(){
            this.$router.push({ name: "people-person-profile", params: { id_person_data_main: this.id_person_data_main } });
        }
    },
};
</script>

<style>
    .app-webform-light-button {
        color: rgba(0, 0, 0, 0.5);
    }

    .app-webform-light-button:hover,  .app-webform-light-button:focus {
        color: rgba(0, 0, 0, 0.7);
    }

    .bg-light{
        background-color: white !important;
    }
</style>
