<template>
    <div>
        <b-form @submit.prevent>
            <b-row v-if="id_document_const_type == 3">
                <b-col cols="12">
                    <small class="text-muted"> {{trans('pw-duos-info',196)}}</small>
                </b-col>
            </b-row>
            <b-row class="pt-1 pb-1" v-if="id_document_const_type == 3">
                <b-col cols="12">
                    <small class="text-muted"> {{trans('click',175)}} </small>
                    <small class="font-weight-bolder"><b-link href="#" @click="openModelInfo">{{trans('here',175)}}</b-link></small>
                    <small class="text-muted"> {{trans('pw-go-to-diploma-register',196)}}</small>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-row v-for="item_file in document.files" :key="item_file.id_document_data_main">
                        <b-col md="8" xl="8">
                            <span>{{ item_file.file_name_user }}</span>
                            <span v-if="id_document_const_type == 5 || id_document_const_type == 6" id="tooltip-expire-date"> | {{item_file.date_to}}</span>
                            <span v-else-if="id_document_const_type == 4 || id_document_const_type == 9" id="tooltip-creation-date"> | {{item_file.date_from}}</span>
                            <!--<b-tooltip target="tooltip-expire-date" triggers="hover">
                               Expire date
                            </b-tooltip>
                            <b-tooltip target="tooltip-creation-date" triggers="hover">
                                Creation date
                            </b-tooltip>-->
                        </b-col>
                        <b-col md="2" xl="2">
                            <span class="text-right font-weight-bolder"> {{ item_file.size }} MB </span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-link href="#" @click="deleteFile(item_file.id_document_data_main)">
                                <span class="text-right" v-if="fDeleting === 0 || (fDeleting != 0 && fDeleting !== item_file.id_document_data_main)">{{trans('remove',175)}}</span>
                                <b-spinner class="ml-2" small v-if="fDeleting === item_file.id_document_data_main" />
                            </b-link>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-bind:class="{ 'mt-2': document.status === 1 }">
                <b-col v-if="(document.files == null || !(document.files.length >= this.maxFilesNumber))">  
                    <div class="image-upload-wrap">
                        <input class="file-upload-input" type="file" :accept="this.id_document_const_type == 7 ? accept_files_diploma : accept_files" v-on:change="addFile" />
                        <div class="drag-text">
                            <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!fUploading" />
                            <b-spinner v-if="fUploading" style="margin-top: 4px; margin-bottom:6px" />
                            <div>
                                <small class="text-muted" v-if="this.id_document_const_type != 7">{{trans('drag-and-drop-files-jpg-png-pdf',180)}}</small>
                                <small class="text-muted" v-if="this.id_document_const_type == 7">{{trans('drag-and-drop-files-doc-pdf',8)}}</small>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-form>
        <b-modal ref="my-modal" centered okVariant="primary" okTitle="DUO" cancelVariant="primary" cancelTitle="DIPLOMAHULP" title="Diplomaregister" size="lg" @ok="handleOk" @cancel="handleCancel">
            <div class="d-block">
                {{trans('pw-duo-downloading-info',196)}}
            </div>

            <div class="d-block font-weight-bolder pt-1">
                {{trans('pw-diploma-in-register',196)}}
            </div>

            <div class="d-block">
                {{trans('pw-two-ways-downloading-digital-extract',196)}}
                <ol>
                    <li v-html="trans('pw-digid-info-one',196)"></li>
                    <li>{{trans('pw-digid-info-two',196)}}</li>
                </ol>
            </div>
        </b-modal>

        <b-modal ref="datepicker-modal" centered okVariant="primary" okTitle="OK" :title="datepickerModalTitle" size="sm" ok-only @ok="handleDatepickerOk">
            <b-row>
                <b-col>
                    <datepicker v-model="selectedDate" :inline="true" :language="languages[language]" :disabled-dates="disabledDates"></datepicker>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import axios from "@axios";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
export default {
    components: {
        Datepicker,
    },

    props: {
                applicationData: Object, 
                document: Object, 
                id_document_const_type: Number, 
                maxFilesNumber: Number,
                accept_files: {
                    default: "image/png, image/jpeg, image/jpg, application/pdf",
                    type: String
                },
                accept_files_diploma: {
                    default: "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf",
                    type: String
                }
            },
    
    data() {
        return {
            formData: new FormData(),
            datepickerModalTitle: "",
            fUploading: false,
            fDeleting: 0,
            selectedDate: "",
            language: "nl",
            languages: lang,
            disabledDates: {
                to: moment()
                    .subtract(1, "days")
                    .toDate(),
            },
        };
    },

    methods: {
        openModelInfo() {
            this.$refs["my-modal"].show();
        },
        openDatepickerModal(){
            this.$refs["datepicker-modal"].show();
        },
        handleOk() {
            window.open("https://www.duo.nl/particulier/inloggen-op-mijn-duo.jsp", "_blank").focus();
        },
        handleCancel() {
            window.open("https://www.duo.nl/particulier/uittreksel-diplomagegevens-downloaden.jsp", "_blank").focus();
        },
        handleDatepickerOk(){
       
            if(this.id_document_const_type == 5 || this.id_document_const_type == 6){
                if(moment(this.selectedDate).format("yyyy-MM-DD") == "Invalid date"){
                    this.formData.append("date_to", moment().format("yyyy-MM-DD"));
                }else{
                    this.formData.append("date_to", moment(this.selectedDate).format("yyyy-MM-DD"));
                }

            }else if(this.id_document_const_type == 4 || this.id_document_const_type == 9){
                if(moment(this.selectedDate).format("yyyy-MM-DD") == "Invalid date"){
                    this.formData.append("date_from", moment().format("yyyy-MM-DD"));
                }else{
                    this.formData.append("date_from", moment(this.selectedDate).format("yyyy-MM-DD"));
                }
            }

            this.sendFile();
        },
        addFile(e) {

            var file = e.target.files[0];

            if(this.id_document_const_type != 7 && !file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*')) {
                alert(this.trans('only-jpg-png',180));
                file = "";
                return;
            }else if(this.id_document_const_type == 7 && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document.*') && !file.type.match('application/msword.*') && !file.type.match('application/pdf.*')) {
                alert(this.trans('only-doc-pdf',177));
                file = "";
                return;
            }
            this.formData.append("id_person_data_main", this.applicationData.id_person_data_main);
            this.formData.append("file", file);
            this.formData.append("file_name", file.name);
            this.formData.append("id_document_const_type", this.id_document_const_type);
            this.formData.append("file_size", file.size);
            
            if(this.id_document_const_type == 5 || this.id_document_const_type == 6){
                this.disabledDates = {to: moment().subtract(1, "days").toDate()}
                this.datepickerModalTitle = this.trans('set-expiration-date',8);
                this.openDatepickerModal();
            }else if(this.id_document_const_type == 4){
                this.disabledDates = {from: moment().subtract(0, "days").toDate()}
                this.datepickerModalTitle = this.trans('set-creation-date',8);
                this.openDatepickerModal();
            }else if(this.id_document_const_type == 9){
                this.disabledDates = {to: moment().subtract(90, "days").toDate(), from: moment().subtract(0, "days").toDate()}
                this.datepickerModalTitle = this.trans('set-creation-date',8);
                this.openDatepickerModal();
            }else{
                this.sendFile();
            }

        },
        sendFile(){
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
     
            this.fUploading = true;

            axios
                .post("people/profile/web-form/addFile", this.formData, config)
                .then((response) => {
                    this.formData = new FormData();
                    this.parseResponse(response);
                    this.fUploading = false;
                })
                .catch(function(error) {
                    this.fUploading = false;

                    console.log(error);
                });

        },
        deleteFile: function(id_document_data_main) {
            this.fDeleting = id_document_data_main;

            let formData = new FormData();
            formData.append("id_person_data_main", this.applicationData.id_person_data_main);
            formData.append("id_document_data_main", id_document_data_main);
            formData.append("id_document_const_type", this.id_document_const_type);

            axios
                .post("people/profile/web-form/deleteFile", formData)
                .then((response) => {
                    this.parseResponse(response);
                    this.fDeleting = 0;
                })
                .catch((error) => {
                    this.fDeleting = 0;
                    console.error(`error during deleting files: ${error}`);
                });
        },

        parseResponse: function(response) {
            var nDocumentObject = this.document;
            if (response.data != undefined && response.data != null) {
                nDocumentObject.files = response.data.files;
                nDocumentObject.status = response.data.status;
            }
        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('dd-MM-yyyy');
        },
    },
};
</script>

<style scoped>
.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}
.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.fileContainer {
    font-size: 12px;
    border: none;
    padding: 0 0 10px 0;
}
.fileContainer:hover {
    background-color: transparent;
}
.deleteButton {
    color: #71e6ee;
    cursor: pointer;
    float: left;
}
.files {
    width: 43%;
}
.loading {
    margin-left: 40%;
}
.uploadIcon {
    width: 13%;
}
</style>
