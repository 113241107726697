<template>
    <div>
        <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2 mb-2">
            <template v-slot:col-1>
                <app-input v-model="cApplicationData.iban" :label="cIbanLabel"  :additionalInfo="trans('pw-id-front-side-info',196)" validatorRules="required|eleven-proof" validatorName="IBAN" mask="lettersE3-32" @change="checkIban()"/>
            </template>
        </app-row-left-label>
        
    </div>
    
</template>
<script>

import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue'

export default {

    components: {
        PeopleApplicantsProfileWizardDocumentComponent
    },
    
    props:{
        applicationData: Object,
        refresh_bank_error: {}
    },

    created(){

    },

    
    computed: {
        cApplicationData:{
            get() { return this.applicationData },
            set(value) { this.$emit("update:applicationData", value) }
        },

        cRefreshBankError:{
            get() { return this.refresh_bank_error },
            set(value) { this.$emit("update:refresh_bank_error", value) }
        },

        cIbanLabel() {
            if(this.applicationData.applicant_type == 1){//UZK
                return this.trans('pw-account-number',196);
            }else if(this.applicationData.applicant_type == 2){//ZZP
                return this.trans('pw-business-bank-account-number',196);
            }else{
                return this.trans('pw-iban',196);
            }
        }
    },

    data(){
        return{
            
        }
    },

    methods: {
        checkIban(){

            let value = this.cApplicationData.iban.toUpperCase().replace(/\s/g, "");
            let rearrange = value.substring(4, value.length) + value.substring(0, 4);
            let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            let alphaMap = {};
            let number = [];

            alphabet.forEach((value, index) => {
                alphaMap[value] = index + 10;
            });
            rearrange.split("").forEach((value, index) => {
                number[index] = alphaMap[value] || value;
            });
            if (this.modulo(number.join("").toString(), 97) === 1) {
                this.cRefreshBankError++;
            }

        },

        modulo(aNumStr, aDiv){
            var tmp = "";
            var i, r;
            for (i = 0; i < aNumStr.length; i++) {
                tmp += aNumStr.charAt(i);
                r = tmp % aDiv;
                tmp = r.toString();
            }
            return tmp / 1;
        }
    }

}
</script>
<style scoped>
.app-local-margin{
    margin-bottom: 10px !important;
}
</style>