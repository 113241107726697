<template>
    <b-modal ref="modal-add" size="lg" v-model="computedShow" :title="trans('pw-retirement-history',196)">
        
        <b-alert variant="info" show class="mb-3">
            <div class="text-md-start mb-2">{{trans('pw-retirement-info',196)}}</div>
        </b-alert> 
    
        <b-tabs ref="tabs" class="mb-3" justified>
            <b-tab :title="this.trans('pw-plus-scheme',196)">
                <b-card>
                    <img class="app-local-img my-2" src="@/assets/images/personWizard/plus_regulation.jpeg" alt="plus_regulation" /> 
                </b-card>
            </b-tab>  
            <b-tab :title="this.trans('pw-basic-scheme',196)">
                <b-card>
                    <img class="app-local-img my-2" src="@/assets/images/personWizard/basic_regulation.jpeg" alt="basic_regulation" />
                </b-card>
            </b-tab>
        </b-tabs>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";

export default {
    components: {
        
    },

    props: ["show", "args", "return", "result"],

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    methods: {    

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>

.app-local-img{
    height: auto;
    width: 100%;
    margin: auto;
    display: block;
}

</style>
